import { isLanguage } from './i18n.shared'
import { messages } from './i18n.messages'

export function i18n(language: string) {
	return {
		formatMessage: isLanguage('en', language)
			? (input: { defaultMessage: string }) => input.defaultMessage
			: ({ defaultMessage }: { defaultMessage: string }) =>
					messages[language]?.[defaultMessage] ?? `🙈 ${defaultMessage}`,
	}
}
